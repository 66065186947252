import React from "react"

import { useStaticQuery, graphql, Link } from "gatsby"

export const query = graphql`
  query {
    allStrapiProyectosecurity(
      limit: 3
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          titulo
          slug
          id
          imagen_blanco_negro {
            url
          }
        }
      }
    }
  }
`

export default function Proyectoshome() {
  const data = useStaticQuery(query)
  return (
    <>
      {data.allStrapiProyectosecurity.edges.map(({ node }, key) => (
        <Link
          to={`/proyectos/${node.slug}/`}
          className="link-proyecto"
          key={key}
        >
          <div className="card-proyecto">
            <div className="card-proyecto-overlay"></div>
            <div className="card-proyecto-titulo">
              <h3>{node.titulo}</h3>
            </div>
          </div>
          <div className="caja-background">
            <div
              className="background-proyecto"
              style={{
                backgroundImage: `url(${process.env.API_URL}${node.imagen_blanco_negro.url})`,
              }}
            ></div>
          </div>
        </Link>
      ))}
    </>
  )
}
