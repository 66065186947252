import React from "react"
import Layout from "../components/layout"
import Postshome from "../components/postshome"
import Seo from "../components/SEO/SEO"
import Boton from "../components/boton"
import { StaticImage } from "gatsby-plugin-image"
import { IconContext } from "react-icons"
import {
  GrCompliance,
  GrServerCluster,
  GrCloudUpload,
  GrShield,
} from "react-icons/gr"
import "../styles/index.scss"
import "../styles/proyectoshome.scss"
import Proyectoshome from "../components/proyectoshome"

const Inicio = props => {
  return (
    <Layout>
      <Seo
        pathname={props.location.pathname}
      />


      {/* FOTO CABECERA */}
      <section className="foto-entrada bloque-oscuro">
        {/* CABECERA */}
        <section className="limitador-inicio header-inicio">
          <div className="detalle destacado">
          </div>
          <div className="titulo">
            <h1>En ciberseguridad no vale con mantener la distancia.</h1>
            <p>
              Un equipo 10 con CPD propio trabajando por la seguridad de tu
              empresa nos convierte en el mejor socio para garantizar tu confianza
              en la tecnología.
            </p>
            <Boton
              color="secundario"
              url="/contacto/"
              texto="Contactar"
              externo={false}
            />
          </div>
        </section>
        {/* FIN CABECERA */}

      </section>

      {/* FIN FOTO CABECERA */}

      {/* SERVICIOS */}
      <section className="limitador-inicio header-inicio">
        <div className="detalle destacado">
          <p>Servicios</p>
        </div>
        <div className="titulo">
          <h2>
            Hacemos fácil la tecnología: te explicamos cada servicio para que
            seas partícipe de cada mejora e intervención.
          </h2>
        </div>
        <div className="iconos-servicios">
          <IconContext.Provider
            value={{
              fill: "#1f1f1f",
              stroke: "#1f1f1f",
              color: "#1f1f1f",
              size: "60%",
              className: "serv-icono-svg",
            }}
          >
            <div className="servicios-card">
              <div className="serv-icono">
                <GrCompliance />
              </div>
              <div className="serv-texto">
                <h3>Compliance</h3>
                <p>
                  Gestiona el riesgo. Este servicio te ayuda con los
                  procedimientos y buenas prácticas para identificar, clasificar
                  los riesgos y, además, prevenirlos, controlarlos y hacer
                  frente a ellos.
                </p>
              </div>
            </div>
            <div className="servicios-card">
              <div className="serv-icono">
                <GrShield />
              </div>
              <div className="serv-texto">
                <h3>Ciberseguridad</h3>
                <p>
                  Nuestro proceso más lógico: conocer el estado de tus sistemas
                  y de tu información, solucionar y evitar riesgos con acciones
                  y monitorizar cada elemento crítico de tu red. El objetivo
                  siempre: que seas invulnerable.{" "}
                </p>
              </div>
            </div>
            <div className="servicios-card">
              <div className="serv-icono">
                <GrCloudUpload />
              </div>
              <div className="serv-texto">
                <h3>Cloud</h3>
                <p>
                  Muchos dicen que tienen CPD propio, nosotros lo tenemos. Y
                  ofrecemos los servicios cloud más seguros y accesibles del
                  mercado. Conocerás a nuestro equipo y sabrás en todo momento
                  dónde están y quién protege tus datos.
                </p>
              </div>
            </div>
            <div className="servicios-card">
              <div className="serv-icono">
                <GrServerCluster />
              </div>
              <div className="serv-texto">
                <h3>Sistemas Avanzados</h3>
                <p>
                  Un equipo certificado con los principales fabricantes
                  tecnológicos y experiencia amplia en sistemas informáticos
                  hace que superemos cada reto que nos ponen delante. Tu
                  organización no se nos va a resistir.
                </p>
              </div>
            </div>
          </IconContext.Provider>
        </div>
        <div className="grid-kit-digital">
          <div className="col-i col-kit">
            <h2>Y ahora todo es más fácil con Kit Digital</h2>
            <p>
              Subvenciones a fondo perdido para la digitalización de tu empresa:
              para que puedas avanzar con el menor esfuerzo. Bullhost es Agente Digitalizador del programa Kit Digital y estamos autorizados a gestionar para tí estas ayudas.</p>
            <Boton
              // color="secundario"
              color="primario"
              url="/kit-digital/"
              texto="Más información"
              externo={false}
            />
          </div>
          <div className="col-d col-kit">
            <StaticImage
              src="../../static/img/landings/kitdigital/kit-digital-tablet-800.webp"
              objectFit="contain"
              alt="Bullhost y Kit Digital"
            />
          </div>
        </div>
      </section>
      {/* FIN SERVICIOS */}
      {/* <section id="bloque-kit-digital" class="limitador-inicio">
        <div className="grid-kit-digital">
          <div className="col-i col-kit">
            <h2>Y ahora todo es más fácil con Kit Digital</h2>
            <p>
              Subvenciones a fondo perdido para la digitalización de tu empresa:
              para que puedas avanzar con el menor esfuerzo. Bullhost es Agente
              Digitalizador y estamos autorizados a gestionar para tí estas
              ayudas. Puedes leer la info ampliada{" "}
              <Link rel="stylesheet" to="/kit-digital/">
                aquí
              </Link>
              .
            </p>
          </div>
          <div className="col-d col-kit">
            <StaticImage
              src="../../static/img/kit-digital-tablet.webp"
              objectFit="contain"
              alt="Bullhost y Kit Digital"
            />
          </div>
        </div>
      </section> */}

      {/* PROYECTOS */}
      <section
        id="seccion-proyectos"
        className="bloque-proyectos bloque-oscuro"
      >
        <Proyectoshome />
      </section>
      {/* FIN PROYECTOS */}

      {/* FABRICANTES */}
      <section className="bloque-oscuro">
        <div className="header-inicio limitador-inicio">
          <div className="detalle destacado">
            <p>Fabricantes</p>
          </div>
          <div className="titulo"></div>
          <div className="iconos-fabricantes fabricantes-index">
            <div className="card-icono">
              <a
                href="https://www.kaspersky.es/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/kaspersky-logo.svg"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Kaspersky"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://www.paloaltonetworks.es/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/paloalto-logo.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Palo Alto"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://www.logpoint.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/logpoint-logo.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Logpoint"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://www.sophos.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/sophos-logo.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Sophos"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://forescouttechnologies.es/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/forescout-logo.svg"
                  style={{ maxWidth: 250 }}
                  objectFit="contain"
                  alt="Forescout"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://aws.amazon.com/es"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/amazon-partner.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Amazon Web Services"
                />
              </a>
            </div>

            <div className="card-icono">
              <a
                href="https://cisco.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/cisco-partner-black.svg"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Cisco"
                />
              </a>
            </div>

            {/* <div className="card-icono">
              <a
                href="https://www.nozominetworks.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/nozomi-logo.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Nozomi Networks"
                />
              </a>
            </div> */}

            <div className="card-icono">
              <a
                href="https://www.veeam.com/es/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage
                  src="../../static/img/logos-fabricantes/veeam-logo.png"
                  style={{ maxHeight: 80 }}
                  objectFit="contain"
                  alt="Veeam"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* FIN FABRICANTES */}

      {/* NOSOTROS */}
      <section className="bloque-acento">
        <div className="header-inicio limitador-inicio">
          <div className="detalle destacado">
            <p>Nosotros</p>
          </div>
          <div className="titulo">
            <h2>Un equipo ciberseguro y comprometido.</h2>
            <p>
              En Bullhost combinamos la experiencia de los que llevamos más de
              20 años en el sector con la gente más jóven y más capaz que
              arranca con todas las ganas.
            </p>
          </div>
          <div className="cards-equipo">
            <div className="card-equipo">
              <StaticImage
                src="../../static/img/equipo-1.jpg"
                alt="Compromiso"
              />
            </div>
            <div className="card-equipo">
              <StaticImage
                src="../../static/img/equipo-2.jpg"
                alt="CPD propio"
              />
            </div>
            <div className="card-equipo">
              <StaticImage
                src="../../static/img/equipo-3.jpg"
                alt="Profesionalidad"
              />
            </div>
            <div className="card-equipo">
              <StaticImage
                src="../../static/img/equipo-4.jpg"
                alt="Asesoramiento"
              />
            </div>
          </div>
        </div>
      </section>
      {/* FIN NOSOTROS */}

      {/* BLOG */}
      <section className="limitador-inicio header-inicio">
        <div className="detalle destacado">
          <p>Blog</p>
        </div>
        <div className="titulo">
          <h2>
            Las noticias, hallazgos y secretos más inconfesables de Bullhost
          </h2>
        </div>
        <div className="bloque-cards-blog">
          <Postshome />
        </div>
        <div className="mas-noticias-blog">
          <Boton
            color="acento"
            url="/blog/"
            texto="Ver más noticias"
            externo={false}
          />
        </div>
      </section>
      {/* FIN BLOG */}

      {/* CONTACTO */}
      <section className="bloque-oscuro contacto-background">
        <div className="overlay-contacto"></div>
        <div className="header-inicio limitador-inicio">
          <div className="detalle destacado">
            <p>Contacto</p>
          </div>
          <div className="titulo">
            <h2>¿Quieres sumar con nosotros para tu próximo proyecto?</h2>
            <p>
              La tecnología avanza con pasos de gigantes, pero a día de hoy,
              hablar y exponer las inquietudes y las ideas sigue siendo la mejor
              forma de aclararse. Así que te invitamos a hablar.
            </p>
            <Boton
              color="secundario"
              url="/contacto/"
              texto="Contactar"
              externo={false}
            />
          </div>
        </div>
      </section>
      {/* FIN CONTACTO */}
    </Layout>
  )
}

export default Inicio
