import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../styles/postsHome.scss"

export const query = graphql`
  query {
    allStrapiPostSecurity(
      limit: 4
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          titulo
          slug
          categorias {
            nombre
          }
          extracto
          published_at
        }
      }
    }
  }
`

function convertirFecha(fecha) {
  return new Date(fecha).toLocaleDateString("es", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export default function Postshome() {
  const data = useStaticQuery(query)

  return (
    <>
      {data.allStrapiPostSecurity.edges.map(({ node }, key) => (
        <article className="card-blog-index" key={key}>
          <div className="card-blog-cuerpo-index">
            <span className="texto-fecha">
              {convertirFecha(node.published_at)}
            </span>
            <h3>
              <Link to={`/blog/${node.slug}/`}>{node.titulo}</Link>
            </h3>
            <p className="extracto">{`${node.extracto.substring(
              0,
              150
            )}...`}</p>
          </div>
          <div className="categorias">
            {node.categorias.map((value, index) => {
              return <span key={index}>{value.nombre}</span>
            })}
          </div>
        </article>
      ))}
    </>
  )
}
